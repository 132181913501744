<template>
  <div class="h-full">
    <div  class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'EmailTemplateList', params: { entityId: 2, slug: 'job'}})">
        <p class="text-right text-text1 heading-3">
          Email Template
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/emailSvg.svg" class=" h-10" alt="img"></span>
        </div>
      </div>
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'AppSetting'})">
        <p class="text-right text-text1 heading-3">
          App Setting
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/mobileSvg.svg" class=" h-10" alt="img"></span>
        </div>
      </div>
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'Plans'})">
        <p class="text-right text-text1 heading-3">
          Plan Management
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/subscription.svg" class=" h-14" alt="img"></span>
        </div>
      </div>
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'EmailConfiguration'})">
        <p class="text-right text-text1 heading-3">
          Email Configuration
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/emailConf.svg" class=" h-12" alt="img"></span>
        </div>
      </div>
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'PasswordManager'})">
        <p class="text-right text-text1 heading-3">
          Password Management
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/passMan.svg" class=" h-14" alt="img"></span>
        </div>
      </div>
      <div style="height: 100px;" class="relative shadow cursor-pointer rounded p-4 bg-white mt-10" @click="$router.push({name: 'UserDetail'})">
        <p class="text-right text-text1 heading-3">
          User Profile
        </p>
        <div class="card_icon bg-blue-200 flex justify-center items-center">
          <span><img src="@/assets/images/svg/profile.svg" class=" h-14" alt="img"></span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      userDetail: {},
      settingOptions: [],
      cardHeight: window.innerHeight - 94
    }
  },
  mounted () {
    document.title = 'Setting Dashboard'
    let userData = localStorage.getItem('employeeData')
    this.userDetail = JSON.parse(userData)
  },
  methods: {
  }
}
</script>

<style scoped>
.card-content {
  background-color: transparent;
  padding: 0rem;
  padding-top: 1rem;
  padding-right: 1rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
}
.sheets {
  border-radius: 10%;
  width: 70px !important;
  height: 67px !important;
  justify-content: center;
  display: flex;
  margin-top: -40px;
  border-color: #26c6da;
}
.hoverableCard:hover {
  box-shadow: 1px 5px 5px 1px rgba(38, 38, 38, 0.2);
  top: 0px;
  border: 0px solid #cccccc;
  /* background-color: rgb(244, 244, 244); */
  /* box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.2); */
  /* transition:all 0.5s; */
  /* border: 0px solid #cccccc; */
}
.cardAbsolute {
  position: absolute;
  left: 20px;
  top: -30px;
  border-radius: 8%;
  width: 80px !important;
  height: 80px !important;
  text-align: center;
  justify-content: center;
  display: flex;
}
.marginTop {
  margin-top: 30px;
}

.card_icon{
    position: absolute;
    left: 20px;
    top: -30px;
    border-radius: 8%;
    width: 100px!important;
    height: 100px!important;
    text-align: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
</style>
