var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full"},[_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4"},[_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'EmailTemplateList', params: { entityId: 2, slug: 'job'}})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" Email Template ")]),_vm._m(0)]),_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'AppSetting'})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" App Setting ")]),_vm._m(1)]),_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'Plans'})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" Plan Management ")]),_vm._m(2)]),_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'EmailConfiguration'})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" Email Configuration ")]),_vm._m(3)]),_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'PasswordManager'})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" Password Management ")]),_vm._m(4)]),_c('div',{staticClass:"relative shadow cursor-pointer rounded p-4 bg-white mt-10",staticStyle:{"height":"100px"},on:{"click":function($event){return _vm.$router.push({name: 'UserDetail'})}}},[_c('p',{staticClass:"text-right text-text1 heading-3"},[_vm._v(" User Profile ")]),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-10",attrs:{"src":require("@/assets/images/svg/emailSvg.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-10",attrs:{"src":require("@/assets/images/svg/mobileSvg.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-14",attrs:{"src":require("@/assets/images/svg/subscription.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-12",attrs:{"src":require("@/assets/images/svg/emailConf.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-14",attrs:{"src":require("@/assets/images/svg/passMan.svg"),"alt":"img"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card_icon bg-blue-200 flex justify-center items-center"},[_c('span',[_c('img',{staticClass:"h-14",attrs:{"src":require("@/assets/images/svg/profile.svg"),"alt":"img"}})])])
}]

export { render, staticRenderFns }